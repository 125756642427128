import { Link, Outlet } from 'react-router-dom';
import NavbarComponent from '@components/navbar/NavbarComponent';



function App() {
    return (
        <div>
            <NavbarComponent></NavbarComponent>
            <Outlet />
        </div>
    );
}

export function NoMatch() {
    return (
      <div className="p-4">
        <h2>Page not found!</h2>
        <p>
          <Link to="/">Go to the home page</Link>
        </p>
      </div>
    );
  }

export default App;
