import React, { ReactElement } from "react";
import "./loader.scss";

const Loader = (): ReactElement => {
    return (
        <div className="loader-overlay">
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      );
};

export default Loader;
