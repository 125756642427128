import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.min'
import {QueryClient, QueryClientProvider} from '@tanstack/react-query';
import {RouterProvider} from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import { CookiesProvider } from 'react-cookie';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import {ProSidebarProvider} from "react-pro-sidebar";
import App from "./App";
import ReceiptPage from '@pages/receipt/ReceiptPage';
import OutputTaxPageComponent from '@pages/receipt/OutputTax';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
        },
    },
});


root.render(
    <React.StrictMode>
            <QueryClientProvider client={queryClient}>
             {/* <App/> */}
                <Router basename={process.env.PUBLIC_URL}>
                    <CookiesProvider>
                        <Routes>
                            <Route path="/" element={<App />}>
                                <Route path="/receipt" element={<ReceiptPage/>} />
                                <Route path="/outputTax" element={<OutputTaxPageComponent/>} />
                            </Route>
                        </Routes>
                    </CookiesProvider>
                </Router>
            </QueryClientProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
