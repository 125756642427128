import React, { ReactElement, useState, useEffect } from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import "./output-tax.scss";
import Loader from "@components/loader/Loader";
import { findAllCompany, generateOutputTax } from "@services/employee.service";
import { Company } from "@models/company.model";
import Swal from "sweetalert2";
import { AxiosError } from "axios";
// import EtaxService from 'path/to/EtaxService';
const defaultCompanyList = [
  { company: "DAE", companyName: "DAE", id: 1 },
  { company: "Company 2", companyName: "Company Name 2", id: 2 },
  { company: "Company 3", companyName: "Company Name 3", id: 3 },
  { company: "Company 4", companyName: "Company Name 4", id: 4 },
  { company: "Company 5", companyName: "Company Name 5", id: 5 },
];

const OutputTaxPageComponent = (): ReactElement => {
  const [dateType] = useState("daily");
  const [selectedDate] = useState(new Date());
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1);
  const [companyList, setCompanyList] = useState<Company[]>([]);
  const [company, setCompany] = useState<Company>();
  const [onLoad, setOnLoad] = useState(false);
  const [status, setStatus] = useState<string>("false"); // Initialize the default status value

  // ...

  const handleStatusChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setStatus(e.target.value);
  };
  useEffect(() => {
    console.log("dataType", dateType);

    const fetchCompanies = async () => {
      try {
        const res = await findAllCompany();
        setCompanyList([
          ...res.data,
          { company: "ALL", companyName: "ALL", id: 99 } as Company,
        ]);
        const searchedObject = res.data.find((obj) => obj.company === "DAE");
        console.log("searchedObject", searchedObject);
        setCompany(searchedObject);
      } catch (error) {
        console.error(error);
      }
    };

    fetchCompanies();
    console.log("companyList", companyList);
  }, []); // Run once on component mount

  const onItemSelect = (item: any) => {
    console.log("item", item);
    setCompany(item[0]);
    console.log("Selected item:", item[0]);
  };
  const openModal = (title: string, isSuccess: boolean, message?: string) => {
    Swal.fire({
      title: title,
      html: message ? message : "",
      icon: isSuccess ? "success" : "error",
      confirmButtonText: "Close",
      customClass: {
        confirmButton: "btn btn-secondary",
      },
      buttonsStyling: false,
    });
  };
  const run = async () => {
    setOnLoad(true);
    console.log("year", selectedYear);
    console.log("month", selectedMonth);
    console.log("company", company);
    try {
      await generateOutputTax({
        company: company?.company?.toString() ?? "",
        month: selectedMonth.toString(),
        year: selectedYear.toString(),
        status: status,
      });
      setOnLoad(false);
      console.log("Process Success");
      openModal("Send Email Success", true);
    } catch (err) {
      const axiosError = err as AxiosError;
      if (axiosError.response) {
        setOnLoad(false);

        // The request was made, but the server responded with a status code outside the range of 2xx
        const statusCode = axiosError.response.status;
        switch (statusCode) {
          case 404:
            console.log("Error: Not Found (404)");
            openModal("ไม่พบข้อมูล", false);
            // Handle the 404 error case here
            break;
          case 500:
            openModal("Internal Server Error (500)", false);
            console.log("Error: Internal Server Error (500)");
            // Handle the 500 error case here
            break;
          // Add more cases for other status codes as needed
          default:
            openModal("Unhandled Error", false);
            console.log("Unhandled Error:", axiosError);
            break;
        }
      } else if (axiosError.request) {
        // The request was made, but no response was received
        openModal("No response receivedr", false);
        console.log("Error: No response received");
        // Handle the case where no response was received from the server
      } else {
        // Something happened in setting up the request that triggered an error
        openModal("Error: " + axiosError.message, false);
        console.log("Error:", axiosError.message);
        // Handle other errors, e.g., network issues or something else
      }
    }
  };

  return (
    // <div className="container pt-3 " style={{ width: "50vw" }}>
    //   <div className="row pb-5">
    //     <h5>Output Tax</h5>
    //   </div>
    //   <div className="row align-items-center pb-3">
    //     <div className="col-sm-10">
    //       <div className="d-flex align-items-center">
    //         <label htmlFor="company" className="me-2">
    //           Company:
    //         </label>
    //         <Typeahead
    //           id="company"
    //           options={companyList}
    //           labelKey="company"
    //           onChange={onItemSelect}
    //           minLength={0}
    //           placeholder=""
    //           style={{ width: "118px" }}
    //           selected={company ? [company]:[]}
    //           // defaultSelected={company ? [company] : []}
    //         />
    //         <div className="ms-4">
    //           <span className="me-2">Status:</span>
    //           <label className="me-2" htmlFor="cancelRadio">
    //             ยกเลิก
    //           </label>
    //           <input
    //             type="radio"
    //             id="cancelRadio"
    //             name="statusRadio"
    //             value="true"
    //             checked={status === "true"}
    //             onChange={handleStatusChange}
    //           />
    //           <label className="ms-4 me-2" htmlFor="normalRadio">
    //             ปกติ
    //           </label>
    //           <input
    //             type="radio"
    //             id="normalRadio"
    //             name="statusRadio"
    //             value="false"
    //             checked={status === "false"}
    //             onChange={handleStatusChange}
    //           />
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    //   <div className="row align-items-center">
    //     <div className="col-sm-8">
    //       <div className="d-flex">
    //         <label htmlFor="year" className="me-2">
    //           Year:
    //         </label>
    //         <input
    //           max="2030"
    //           min="2000"
    //           type="number"
    //           id="inputYear"
    //           value={selectedYear}
    //           onChange={(e) => setSelectedYear(parseInt(e.target.value))}
    //           className="form-control"
    //           style={{ width: "150px" }}
    //         />
    //         <label htmlFor="month" className="ms-4 me-2">
    //           Month:
    //         </label>
    //         <select
    //           className="form-select"
    //           value={selectedMonth}
    //           onChange={(e) => setSelectedMonth(parseInt(e.target.value))}
    //           style={{ width: "150px" }}
    //         >
    //           <option value="1">January</option>
    //           <option value="2">February</option>
    //           <option value="3">March</option>
    //           <option value="4">April</option>
    //           <option value="5">May</option>
    //           <option value="6">June</option>
    //           <option value="7">July</option>
    //           <option value="8">August</option>
    //           <option value="9">September</option>
    //           <option value="10">October</option>
    //           <option value="11">November</option>
    //           <option value="12">December</option>
    //         </select>
    //       </div>
    //     </div>
    //     <div className="col-sm-2">
    //       <button
    //         className="btn btn-primary"
    //         onClick={run}
    //         style={{ width: "100px" }}
    //       >
    //         Run
    //       </button>
    //     </div>
    //   </div>
    //   {onLoad && <Loader />}
    //   {/* <div
    //     className="app-loader-override"
    //     style={{ display: onLoad ? "block" : "none" }}
    //   >
    //     <Loader />
    //   </div> */}
    // </div>

    <div className="container_custom " style={{ width: "50vw" }}>
      <div>
        <h3>OutputTax</h3>
      </div>
      <hr className="primary-line" />
      <div className="row align-items-center pb-3">
        <div className="col-sm-10">
          <div className="d-flex align-items-center">
            <label htmlFor="company" className="me-2">
              Company:
            </label>
            <Typeahead
              id="company"
              options={companyList}
              labelKey="company"
              onChange={onItemSelect}
              minLength={0}
              placeholder=""
              style={{ width: "118px" }}
              selected={company ? [company] : []}
              // defaultSelected={company ? [company] : []}
            />
            <div className="ms-4">
              <span className="me-2">Status:</span>
              <label className="me-2" htmlFor="cancelRadio">
                ยกเลิก
              </label>
              <input
                type="radio"
                id="cancelRadio"
                name="statusRadio"
                value="true"
                checked={status === "true"}
                onChange={handleStatusChange}
              />
              <label className="ms-4 me-2" htmlFor="normalRadio">
                ปกติ
              </label>
              <input
                type="radio"
                id="normalRadio"
                name="statusRadio"
                value="false"
                checked={status === "false"}
                onChange={handleStatusChange}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="row align-items-center">
        <div className="col-sm-8">
          <div className="d-flex">
            <label htmlFor="year" className="me-2">
              Year:
            </label>
            <input
              max="2030"
              min="2000"
              type="number"
              id="inputYear"
              value={selectedYear}
              onChange={(e) => setSelectedYear(parseInt(e.target.value))}
              className="form-control"
              style={{ width: "150px" }}
            />
            <label htmlFor="month" className="ms-4 me-2">
              Month:
            </label>
            <select
              className="form-select"
              value={selectedMonth}
              onChange={(e) => setSelectedMonth(parseInt(e.target.value))}
              style={{ width: "150px" }}
            >
              <option value="1">January</option>
              <option value="2">February</option>
              <option value="3">March</option>
              <option value="4">April</option>
              <option value="5">May</option>
              <option value="6">June</option>
              <option value="7">July</option>
              <option value="8">August</option>
              <option value="9">September</option>
              <option value="10">October</option>
              <option value="11">November</option>
              <option value="12">December</option>
            </select>
          </div>
        </div>
        <div className="col-sm-2">
          <button
            className="btn btn-primary"
            onClick={run}
            style={{ width: "100px" }}
          >
            Run
          </button>
        </div>
      </div>
      {onLoad && <Loader />}
    </div>
  );
};

export default OutputTaxPageComponent;
