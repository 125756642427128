import { ReceiptModel } from "@models/receipt.model";
import { TDX_RECEIPT_SERVICE } from "./base.service";

const getReceiptByDocSeqNbrAndCompany = (docSeqNbr: string, company: string): Promise<ReceiptModel> => {
return TDX_RECEIPT_SERVICE.get<ReceiptModel>(`/receipt/find-by-doc-sequence-number-and-company`,
    {
      params: {
        docSeqNbr, company
      }
    }).then(res => res.data)
    .catch((err) => err);
};


const updateReceipt = (receipt: ReceiptModel) => {
    return TDX_RECEIPT_SERVICE.put<ReceiptModel>('/receipt', receipt).then(res => res);
};

const openPDFInNewTab = (docSeqNbr: string, company: string) => {
    // TDX_RECEIPT_SERVICE.get(`/receipt/view-pdf-receipt`, {
    //   responseType: 'arraybuffer',
    //   params: { docSeqNbr, company },
    // })
    //   .then((response) => {
    //     const file = new Blob([response.data], { type: 'application/pdf' });
    //     const fileURL = URL.createObjectURL(file);
  
    //     // Create a new tab
    //     const newTab = window.open('', '_blank','width=800,height=600');
  
    //     if (newTab) {
    //       // Write HTML content to the new tab
    //       newTab.document.write(`
    //         <!DOCTYPE html>
    //         <html>
    //           <head>
    //             <title>PDF Viewer</title>
    //           </head>
    //           <body style="margin: 0; overflow: hidden;">
    //             <iframe src="${fileURL}" style="border: none; width: 100%; height: 100vh;"></iframe>
    //           </body>
    //         </html>
    //       `);
    //     } else {
    //       console.error('Unable to open PDF in new tab');
    //     }
    //   })
    //   .catch((error) => {
    //     console.error('Error:', error);
    //   });



  TDX_RECEIPT_SERVICE.get(`/receipt/view-pdf-receipt`, {
    responseType: 'arraybuffer',
    params: {
      docSeqNbr, company
    },
  })
    .then((response) => {
      const file = new Blob([response.data], { type: 'application/pdf' });
      const fileURL = URL.createObjectURL(file);
      // const newTab = window.open(fileURL, '_blank');
      // window.location.href = fileURL
      let a= document.createElement('a');
      a.target= '_blank';
      a.href= fileURL;
      a.click();
    

      // if (!newTab) {
      //   console.error('Unable to open PDF in new tab');
      // }
    })
    .catch((error) => {
      console.error('Error:', error);
    });
};

export { getReceiptByDocSeqNbrAndCompany, updateReceipt,openPDFInNewTab };
