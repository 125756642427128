import { AxiosResponse } from "axios";
import { UserProfileModel } from "../models/user-profile.model";
import { TDX_RECEIPT_SERVICE } from "./base.service";
import { Company } from "@models/company.model";
import { PRCompanyModel } from "@models/pr-company.model";

const getUserProfile = (): Promise<AxiosResponse<UserProfileModel>> => {
  return TDX_RECEIPT_SERVICE.get<UserProfileModel>("/employee/session-profile");
};

const findAllCompany = (): Promise<AxiosResponse<Company[]>> => {
  return TDX_RECEIPT_SERVICE.get<Company[]>("/company/all");
};
const findAllPrCompany = (): Promise<AxiosResponse<PRCompanyModel[]>> => {
  return TDX_RECEIPT_SERVICE.get<PRCompanyModel[]>("/company/pr-company/all");
};
const generateOutputTax = (body: OutputTax): Promise<AxiosResponse<any>> => {
  return TDX_RECEIPT_SERVICE.post<any>(
    `/generate-etax-xml/output-tax`,
    body,
    {}
  );
};
export { getUserProfile, findAllCompany, generateOutputTax,findAllPrCompany };

export interface OutputTax {
  company: string;
  month: string;
  year: string;
  status: string;
}
