import axios from 'axios';

const TDX_RECEIPT_SERVICE = axios.create({
    baseURL: process.env.REACT_APP_API_ENDPOINT,
    // withCredentials: true,
    // headers: {
    //     'env': process.env.REACT_APP_ENV || ''
    // }
});


TDX_RECEIPT_SERVICE.interceptors.response.use((resp) => {
    console.log("intercep tors")
    return resp;
}, (err) => {
    console.log("interceptors",err)
    if (err.response.status === 401) {
        localStorage.setItem('back_path', window.location.href);
        localStorage.setItem("redirect", window.location.href);
        console.log((process.env.REACT_APP_API_ENDPOINT || '') + (process.env.REACT_APP_API_LOCAL === 'true' ? '/oauth2local2' : '/oauth2'));
        window.location.href =
          (process.env.REACT_APP_API_ENDPOINT || '') + (process.env.REACT_APP_API_LOCAL === 'true' ? '/oauth2local2' : '/oauth2');
    } else {
        console.error('path ', window.location.href);
        // return err
        return Promise.reject(err)
    }
});


const authen_token = localStorage.getItem('tdx_receipt_token');

export { TDX_RECEIPT_SERVICE, authen_token };
