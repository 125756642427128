import { PRCompanyModel } from "@models/pr-company.model";
import { ReactElement, useEffect, useState, useRef } from "react";
import "./receipt.scss";
import { ReceiptModel } from "@models/receipt.model";
import {
  getReceiptByDocSeqNbrAndCompany,
  updateReceipt,
  openPDFInNewTab,
} from "@services/receipt.service";
import { useForm, UseFormReturn } from "react-hook-form";
import { Card } from "react-bootstrap";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import moment from "moment";
import Swal from "sweetalert2";
import { UserProfileModel } from "@models/user-profile.model";
import { findAllPrCompany, getUserProfile } from "@services/employee.service";

const ReceiptPage = (): ReactElement => {
  const [companies, setCompanies] = useState<PRCompanyModel[]>([]);
  const [company, setCompany] = useState<string>("DAE");
  const [receiptNo, setReceiptNo] = useState<string>("");
  const [receiptDate, setReceiptDate] = useState<string>("");
  const [profile, setProfile] = useState<UserProfileModel>();

  useEffect(() => {
    session();
  }, []);

  const form = useForm<ReceiptModel>({
    resolver: yupResolver(schema),
    defaultValues: initailValues,
  });

  const {
    register,
    setValue,
    getValues,
    handleSubmit,
    control,
    formState: { errors },
  } = form;

  useEffect(() => {
    setCompaniesList();
  }, []);

  const setCompaniesList = async () => {
    try {
      const res = await findAllPrCompany();
      console.log("res :",res)
      setCompanies(res.data);
    } catch (error) {
      console.error(error);
    }
    // setCompanies(companies);
  };
  const viewPDF = () => {
    openPDFInNewTab(receiptNo, company);
  };
  const searchReceipt = () => {
    getReceiptByDocSeqNbrAndCompany(receiptNo, company).then((res) => {
      setReceiptDate(moment(res.docSeqDate).format("DD/MM/YYYY"));
      form.setValue("docSeqNbr", res.docSeqNbr);
      form.setValue("docSeqDate", res.docSeqDate);
      form.setValue("customerName", res.customerName);
      form.setValue("address01", res.address01);
      form.setValue("address02", res.address02);
      form.setValue("address03", res.address03);
      form.setValue("address04", res.address04);
      form.setValue("postal", res.postal);

      console.log("form", form.getValues());
    });
    // const receipt : ReceiptModel = {
    //         id: 1,
    //         createBy: "",
    //         updateBy: "",
    //         createDate: new Date(),
    //         updateDate: new Date(),
    //         company: "DAE",
    //         docSeqNbr: "T230000027194",
    //         lineNbr: 1,
    //         status: "P",
    //         datetimeStamp: new Date(),
    //         docType: "AR-RI",
    //         docSeqDate: new Date(),
    //         depositType: "O",
    //         descr01: "",
    //         creditFlag: "",
    //         customerName: "ภูริตๅๅ เตชจารีพงศ์",
    //         address01: "เลขที่  ",
    //         address02: "",
    //         address03: "",
    //         address04: "",
    //         postal: "99999",
    //         descrLine01: "Commission Fee",
    //         courseName01: "",
    //         courseName02: "",
    //         courseName03: "",
    //         descrLine02: "",
    //         descrLine03: "",
    //         descrLine04: "",
    //         item: "T- Commission 01",
    //         totAmount: 2.25,
    //         vatAmtBase: 2.10,
    //         vatAmt: 0.15,
    //         totalLine: 1,
    //         depositId: "",
    //         oprid: "",
    //         yesOrNo: "N",
    //         taxpayerId: "8888888888889",
    //         customerBranch: "00000",
    //         fromDate: "",
    //         toDate: new Date(),
    //         sendStatus: "0",
    //         reference01: "",
    //         reference02: "",
    //         withholdingAmt: 0.00,
    //         diffAmt: 0.00,
    //         fileName: "",
    //         customerEmailId: "",
    //         date2: new Date(),
    //         isPrinted: false
    //     }
    //   setReceipt(receipt);
  };

  const submit = () => {
    try {
      openConfirmModal("Do you want to submit?", "", (isConfirm) => {
        if (isConfirm) {
          updateReceipt(form.getValues()).then((res) => {
            console.log("res", res);
            modal("Your submission has been uploaded.", true);
          });
        } else {
          return;
        }
      });
    } catch {
      modal("Something went wrong, please try again.", false);
    }
  };

  const openConfirmModal = (
    title: string,
    message: string,
    onConfirm: (confirm: boolean) => void
  ) => {
    Swal.fire({
      title: title,
      text: message,
      showCancelButton: true,
      confirmButtonColor: "#0275d8",
      cancelButtonColor: "",
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then((result) => {
      if (result && result.isConfirmed) {
        onConfirm(true);
      } else {
        onConfirm(false);
      }
    });
  };

  const modal = (title: string, isSuccess: boolean) => {
    Swal.fire({
      title: title,
      icon: isSuccess ? "success" : "error",
      confirmButtonText: "OK",
      customClass: {
        confirmButton: "btn btn-secondary",
      },
      buttonsStyling: false,
    }).then((res) => {});
  };
  const session = async () => {
    const profile = await getUserProfile();
    console.log("profile ", profile.data);
    setProfile(profile.data);
  };
  const checkDept = (action: string) => {
    let deptId: string | undefined = "";
    if (profile?.deptRelation.deptID === undefined) {
      deptId = "";
    } else {
      deptId = profile?.deptRelation.deptID;
    }
    console.log("deptId = ", deptId);
    if (action === "view") {
      const myArray: string[] = ["600044", "600013", "100060"];
      if (myArray.includes(deptId)) {
        return true;
      }
    } else if (action === "full") {
      const myArray: string[] = ["600013", "100060"];
      if (myArray.includes(deptId)) {
        return true;
      }
    } else {
      return false;
    }
  };
  return (
    <>
      <div className="container_custom ">
        <div>
          <h3>Receipt</h3>
        </div>
        <hr className="primary-line" />
        <div className="row">
          <div className="col-sm-2">
            <h6 className="">Company : </h6>
          </div>
          <div className="col-sm-4">
            {companies.length > 0 && (
              <select
                className="form-control"
                onChange={(e) => {
                  setCompany(e.target.value);
                }}
                value={company}
              >
                {companies.length > 0
                  ? companies.map((d: PRCompanyModel, i: number) => (
                      <option key={i} value={d.company}>
                        {d.company}
                      </option>
                    ))
                  : []}
              </select>
            )}
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-sm-2">
            <h6 className="">Receipt No : </h6>
          </div>
          <div className="col-sm-4">
            <input
              type="text"
              onChange={(e) => {
                setReceiptNo(e.target.value);
              }}
              className={`form-control`}
              id="receiptNo"
              placeholder=""
            />
          </div>
          <div className="col-sm-auto">
            <button
              className="btn btn-primary"
              type="button"
              onClick={() => searchReceipt()}
            >
              Search
            </button>
          </div>
        </div>
        {form.getValues("docSeqNbr") ? (
          <form onSubmit={handleSubmit(submit)}>
            <Card className="mt-3">
              <Card.Body>
                <div className="px-3">
                  <div className="py-3">
                    <div className="row d-flex justify-content-between">
                      <div className="col-sm-6 align-self-center">
                        <h5>Detail</h5>
                      </div>
                      <div className="col-sm-6 text-end">
                        {checkDept("view") && (
                          <button
                            className="btn btn-warning"
                            type="button"
                            onClick={() => viewPDF()}
                          >
                            View
                          </button>
                        )}
                      </div>
                    </div>
                    <div className="row mt-2">
                      <div className="col-sm-2 align-self-center">
                        <h6>Receipt Date</h6>
                      </div>
                      <div className="col-sm-4">
                        <input
                          type="text"
                          name="receiptDate"
                          className="form-control"
                          value={receiptDate}
                          disabled={true}
                        />
                        {/* <input
                                                id="purpose"
                                                type="text"
                                                maxLength={1000}
                                                placeholder="Input Purpose"
                                                {...register('purpose')}
                                                disabled={props.mode === ModeEnum.view}
                                                className={`form-control ${errors.purpose?.message ? 'error' : ''}`}
                                            /> */}
                      </div>
                    </div>
                    <div className="row mt-2">
                      <div className="col-sm-2 align-self-center">
                        <h6>Customer Name</h6>
                      </div>
                      <div className="col-sm-4">
                        <input
                          type="text"
                          {...register("customerName")}
                          disabled={false}
                          className={`form-control ${
                            errors.customerName?.message ? "error" : ""
                          }`}
                        />
                      </div>
                    </div>
                    <div className="row mt-2">
                      <div className="col-sm-2 align-self-center">
                        <h6>Address 1</h6>
                      </div>
                      <div className="col-sm-4">
                        <input
                          type="text"
                          className="form-control"
                          {...register("address01")}
                          disabled={false}
                        />
                      </div>
                      <div className="col-sm-2 align-self-center">
                        <h6>Address 2</h6>
                      </div>
                      <div className="col-sm-4">
                        <input
                          type="text"
                          className="form-control"
                          {...register("address02")}
                          disabled={false}
                        />
                      </div>
                    </div>
                    <div className="row mt-2">
                      <div className="col-sm-2 align-self-center">
                        <h6>Address 3</h6>
                      </div>
                      <div className="col-sm-4">
                        <input
                          type="text"
                          className="form-control"
                          {...register("address03")}
                          disabled={false}
                        />
                      </div>
                      <div className="col-sm-2 align-self-center">
                        <h6>Address 4</h6>
                      </div>
                      <div className="col-sm-4">
                        <input
                          type="text"
                          className="form-control"
                          {...register("address04")}
                          disabled={false}
                        />
                      </div>
                    </div>
                    <div className="row mt-2">
                      <div className="col-sm-2 align-self-center">
                        <h6>Postal</h6>
                      </div>
                      <div className="col-sm-4">
                        <input
                          type="text"
                          className="form-control"
                          {...register("postal")}
                          disabled={false}
                        />
                      </div>
                    </div>
                    <div className="row d-flex flex-row-reverse mt-3">
                      <div className="col-sm-auto text-end">
                        {checkDept("full") && (
                          <button className="btn btn-primary" type="submit" onClick={() => submit()}>
                            Submit
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </form>
        ) : null}
      </div>
    </>
  );
};

const schema = yup
  .object()
  .shape({
    docSeqNbr: yup.string().required(),
    docSeqDate: yup.date().required(),
    customerName: yup.string().required(),
    address01: yup.string(),
    address02: yup.string(),
    address03: yup.string(),
    address04: yup.string(),
    postal: yup.string(),
  })
  .required();

const initailValues = {
  id: 0,
  createBy: "",
  updateBy: "",
  createDate: new Date(),
  updateDate: new Date(),
  company: "",
  docSeqNbr: "",
  lineNbr: 0,
  status: "",
  datetimeStamp: new Date(),
  docType: "",
  docSeqDate: new Date(),
  depositType: "",
  descr01: "",
  creditFlag: "",
  customerName: "",
  address01: "",
  address02: "",
  address03: "",
  address04: "",
  postal: "",
  descrLine01: "",
  courseName01: "",
  courseName02: "",
  courseName03: "",
  descrLine02: "",
  descrLine03: "",
  descrLine04: "",
  item: "",
  totAmount: 0,
  vatAmtBase: 0,
  vatAmt: 0,
  totalLine: 0,
  depositId: "",
  oprid: "",
  yesOrNo: "",
  taxpayerId: "",
  customerBranch: "",
  fromDate: "",
  toDate: new Date(),
  sendStatus: "",
  reference01: "",
  reference02: "",
  withholdingAmt: 0,
  diffAmt: 0,
  fileName: "",
  customerEmailId: "",
  date2: new Date(),
  isPrinted: false,
};

export default ReceiptPage;
