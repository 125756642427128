import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import "./navbarComponent.scss";
import setLogo from "./../../assets/SET50th logo.png";
import { Link, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";

function NavbarComponent() {
  const location = useLocation();

  return (
    <>
      <Navbar className="bg-color">
        <div className={"p-2"}>
          <img
            alt={"SET Logo"}
            className={"img-fluid"}
            style={{ width: "20%" }}
            src={setLogo}
          />
        </div>
        <div>
          <h1>
            <b>TDX Portal</b>
          </h1>
        </div>
      </Navbar>
      <Navbar className="nav-bar-bg-color" style={{ paddingTop: "0rem",paddingBottom: "0rem" }}>
        <div style={{ paddingLeft: "3rem" }}>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link
                as={Link}
                to="/receipt"
                className={
                  location.pathname === "/receipt"
                    ? "active-link"
                    : "text-white"
                }
              >
                <h5>RECEIPT</h5>
              </Nav.Link>
              <Nav.Link
                as={Link}
                to="/outputTax"
                className={
                  location.pathname === "/outputTax"
                    ? "active-link"
                    : "text-white"
                }
              >
                <h5>OUTPUTTAX</h5>
              </Nav.Link>
              {/*  <Nav.Link as={Link} to=""><h5 className="text-white">Features</h5></Nav.Link>
              <NavDropdown title={<span className="dropdown-style">Test</span>} id="basic-nav-dropdown">
                <NavDropdown.Item href="page1">Page 1</NavDropdown.Item>
              </NavDropdown> */}
            </Nav>
          </Navbar.Collapse>
        </div>
      </Navbar>
    </>
  );
}

export default NavbarComponent;
